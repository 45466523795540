.asset_card_details{
    padding: 11px 17px!important;
}

.asset_card_img{
    display: block;
    width: 100%;
    max-height: 14rem!important;
}
.asset_link{
    cursor: pointer;
    font-weight: 700;
}