.tab_content_header {
    background-color: #FFFFFF;
    padding: 16px;
    height: 83vh;
    overflow-y: auto;
}

.card_container {
    background-color: #F8F8F8 !important;
    min-height: 74px;
}

.card_heading {
    font-weight: 500;
    color: #212529;
}

.card_heading:hover {
    color: #0052CC !important;
}

.card_body {
    padding: 16px 16px 30px !important;
}