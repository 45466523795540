.listing_wrapper{
    padding-right: 0!important;
    padding-left:0!important;
}
.master_data_menu{
    padding-left: 12px!important;
}
.master_list_wrapper{
    height: 75vh;
    overflow-y: auto;
}

.option_add_link {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 33px;
    text-decoration-line: underline;
    color: #209CFF;
}
.del-option .btn {
    border-radius: 2px;
    padding: 8px;
    margin-top: 4px;
}

.selected_config{
    background-color: #efeded;

}
.link{
    color:black;
}
.link:hover{
    color: black;
}
